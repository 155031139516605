<template>
  <v-card elevation="6">
    <v-card-title
      ><h2
        class="my-3"
        style="text-transform: uppercase; font-weight: normal"
        v-text="$tc('inventorys.movement')"
      ></h2>
      <slot></slot>
    </v-card-title>
    <v-divider> </v-divider>

    <v-simple-table class="pb-5">
      <tr>
        <td>{{ $t("inventorys.cantidad_initial") }}</td>
        <td>{{ $n(inventory.quantity) }}</td>
      </tr>
      <tr>
        <td>{{ $t("inventorys.receipts") }}</td>
        <td>{{ $n(moveView.receipts) }}</td>
      </tr>

      <tr>
        <td>{{ $t("inventorys.issues") }}</td>
        <td>{{ $n(moveView.issues) }}</td>
      </tr>
      <tr>
        <td>{{ $t("inventorys.total") }}</td>
        <td v-bind:class="[{ 'error--text': inventory.low_stock }]">
          {{ $n(moveView.stock) }}
        </td>
      </tr>
    </v-simple-table>
  </v-card>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "InventoryMoveCard",
  props: ["inventory"],
  data() {
    return {
      moveView: { receipts: null, issues: null, stock: null },
    };
  },
  mounted() {},
  methods: {
    ...mapActions("inventory", ["getInventory"]),
    fetchInventorymovements() {
      this.getInventory(this.$route.params.id).then((inventory) => {
        //console.log(inventory);

        this.moveView = inventory;
        //console.log(this.moveView);
      });
    },
  },
};
</script>
<style scoped lang="sass">
.v-data-table
td
  padding: 3px 8px !important
td
  &:first-child
    color: var(--v-gris1-base)
  &:nth-child(2)
    text-align: right
    padding-right: 50px !important
h2
  font-weight: normal
  font-size: 20px
</style>